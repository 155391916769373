import {AjaxDriver} from '../../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../../support/api_headers';
import {Appraisal, ControllerAppraiser} from '../models/appraisal';
import {
    ApiAppraisal,
    apiAppraisalToAppraisal,
    ApiControllerAppraiser,
    apiControllerAppraiserToControllerAppraiser,
} from './models/api_appraisal';

export interface ControllerAppraiserApi {
    requestControllerAppraiserData(email: string): Promise<ControllerAppraiser>;
    assignControllerAppraiser(id: number, data: ControllerAppraiser): Promise<Appraisal>;
    detachControllerAppraiser(id: number): Promise<Appraisal>;
}

export class DefaultControllerAppraiserApi implements ControllerAppraiserApi {
    constructor(private ajaxDriver: AjaxDriver) {}
    public requestControllerAppraiserData(email: string): Promise<ControllerAppraiser> {
        return new Promise<ControllerAppraiser>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/controller-appraiser/data`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        email: email,
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.status === 400) {
                        const data: {error?: string} = await result.json();
                        if (data && data.error) {
                            reject(data.error);
                        }
                    }
                    if (result.ok) {
                        const apiController: ApiControllerAppraiser = await result.json();
                        resolve(apiControllerAppraiserToControllerAppraiser(apiController));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public assignControllerAppraiser(id: number, data: ControllerAppraiser): Promise<Appraisal> {
        return new Promise<Appraisal>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/controller-appraiser/assign`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        salutation: data.salutation ?? '',
                        initials: data.initials ?? '',
                        first_name: data.firstName ?? '',
                        last_name: data.lastName ?? '',
                        company_name: data.companyName ?? '',
                        city: data.city ?? '',
                        email: data.email ?? '',
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.status === 400) {
                        const data: {error?: string} = await result.json();
                        if (data && data.error) {
                            reject(data.error);
                        }
                    }
                    if (result.ok) {
                        const apiAppraisal: ApiAppraisal = await result.json();
                        resolve(apiAppraisalToAppraisal(apiAppraisal));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public detachControllerAppraiser(id: number): Promise<Appraisal> {
        return new Promise<Appraisal>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${id}/controller-appraiser/detach`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: null,
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.status === 400) {
                        const data: {error?: string} = await result.json();
                        if (data && data.error) {
                            reject(data.error);
                        }
                    }
                    if (result.ok) {
                        const apiAppraisal: ApiAppraisal = await result.json();
                        resolve(apiAppraisalToAppraisal(apiAppraisal));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
