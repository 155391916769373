import {ApiAnswer, answerToApiAnswer, apiAnswerToAnswer} from './models/api_answer';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {Answer} from '../models/answer';
import {bugsnagClient} from '../../support/bugsnag_client';
import {removeMilliseconds} from '../../support/floor_seconds';
import {getDefaultAjaxHeaders} from '../../support/api_headers';

export interface AnswerApi {
    storeAnswers(appraisalId: number, answers: Answer[], referenceDate?: Date | null): Promise<Answer[]>;
}

export class DefaultAnswerApi implements AnswerApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    private async handleResponse(response: Response) {
        if (response.ok) {
            const apiAnswers: ApiAnswer[] = await response.json();
            const isoCache: Record<string, Date> = {};
            return apiAnswers.map((apiAnswer) => apiAnswerToAnswer(apiAnswer, isoCache));
        } else {
            // Redirect when unauthenticated
            // TODO: not the proper place to do this
            if (response.status === 401 || response.status === 403 || response.status === 419) {
                const doRedirect = confirm('De sessie is verlopen. Wil je terug naar de inlogpagina?');
                if (doRedirect) {
                    window.location.href = window.location.origin + '/login';
                }
            } else {
                try {
                    bugsnagClient?.notify(response);
                    console.error(response);
                } catch (e) {
                    // Do nothing
                }
            }
            throw new Error(response.statusText);
        }
    }

    public storeAnswers(appraisalId: number, answers: Answer[], referenceDate: Date | null = null): Promise<Answer[]> {
        if (appraisalId === -1) {
            return Promise.resolve(answers);
        }

        return new Promise<Answer[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/answer`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        reference_date: referenceDate !== null ? removeMilliseconds(referenceDate).toISOString() : null,
                        answers: answers.map((answer) => answerToApiAnswer(answer)),
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (response) => {
                    const result = await this.handleResponse(response);
                    resolve(result);
                })
                .catch((error) => reject(error));
        });
    }
}
