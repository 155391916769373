import {Observable} from 'rxjs';
import {filter, map} from 'rxjs/operators';
import {AjaxDriver} from '../../network/driver/ajax_driver';
import {isEmpty} from '../../support/util';
import {getCsrfHeader} from '../../support/api_headers';

export enum TextAISource {
    TRANSITIEVISIE_WARMTE = 'transitievisie_warmte',
}

export enum TextAIRole {
    USER = 'user',
    ASSITANT = 'assistant',
}

export interface TextAIMessage {
    role: TextAIRole;
    content: string;
}

export interface TextAIOptions {
    previousMessages?: TextAIMessage[];
    filePath?: string;
    useFileReferences?: boolean;
}

export interface TextAIApi {
    prompt(prompt: string, context: unknown, options?: TextAIOptions): Observable<string>;
}

export class DefaultTextAIApi implements TextAIApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public prompt(prompt: string, context: unknown, options?: TextAIOptions): Observable<string> {
        return this.ajaxDriver
            .fetchEventSource('/ajax/text-ai/prompt', {
                method: 'POST',
                credentials: 'same-origin',

                headers: {
                    ...getCsrfHeader(),
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    prompt,
                    context,
                    file_path: options?.filePath,
                    use_file_references: options?.useFileReferences,
                    previous_messages: options?.previousMessages,
                }),
            })
            .pipe(
                filter((message) => message.event === 'chunk' && !isEmpty(message.data)),
                map((message) => {
                    return JSON.parse(message.data).text;
                })
            );
    }
}
