import {SearchAppraisal} from '../appraising/models/search_appraisal';
import {
    ApiSearchAppraisal,
    apiSearchAppraisalToSearchAppraisal,
} from '../appraising/network/models/api_search_appraisal';
import {AjaxDriver} from '../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../support/api_headers';

export interface AppraisalApi {
    getNearby(lat: number, long: number): Promise<SearchAppraisal[]>;
}

export class DefaultAppraisalApi implements AppraisalApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getNearby(lat: number, long: number): Promise<SearchAppraisal[]> {
        return new Promise<SearchAppraisal[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/nearby?longitude=${long}&latitude=${lat}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const nearbyApiAppraisals: ApiSearchAppraisal[] = await result.json();
                        resolve(nearbyApiAppraisals.map(apiSearchAppraisalToSearchAppraisal));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
