import {AjaxDriver} from '../../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../../support/api_headers';
import {SystemModalType} from '../models/system_modal';

export interface SystemModalApi {
    dismiss(modalType: SystemModalType): Promise<string | null>;
    seen(modalType: SystemModalType): Promise<string | null>;
    shouldShow(modalType: SystemModalType): Promise<string | null>;
}

export class DefaultSystemModalApi implements SystemModalApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public async dismiss(modalType: SystemModalType): Promise<string | null> {
        return this.call(`/ajax/modal/${modalType}/dismiss`);
    }

    public async seen(modalType: SystemModalType): Promise<string | null> {
        return this.call(`/ajax/modal/${modalType}/dismiss`);
    }

    public async shouldShow(modalType: SystemModalType): Promise<string | null> {
        return this.call(`/ajax/modal/${modalType}/should-show`);
    }

    private async call(url: string): Promise<string | null> {
        try {
            const response = await this.ajaxDriver.fetch(url, {
                method: 'POST',
                credentials: 'same-origin',
                headers: getDefaultAjaxHeaders(),
            });
            if (response.ok) {
                return await response.json();
            }

            return null;
        } catch (e) {
            console.warn(e);
            return null;
        }
    }
}
