import {ApiTaskReference, isApiTaskReference} from './models/api_task_reference';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {TaskReference} from '../models/task_reference';
import {ReferenceSale} from '../appraise/ui/content/questions/advanced/reference_objects_question/v1/models/reference_sale';
import {ApiReferenceSale, referenceObjectToApiReferenceObject} from './models/api_reference_sale';
import {ReferenceObjectsError} from './reference_objects_api';
import {ObjectType} from '../enum/object_type';
import {getDefaultAjaxHeaders} from '../../support/api_headers';

export interface GivenPreselectionReferenceObject {
    houseNumber: string;
    letter: string;
    postalCode: string;
    buildYear: number;
    floorArea: number;
    plotArea: number;
    objectType: string;
    energyLabel: string;
}

export interface PreselectedReferenceObjectApiData {
    objects: ApiReferenceSale[];
    given: GivenPreselectionReferenceObject;
}

export interface AltumSettings {
    housetype_comparibility: AltumSettingsComparability;
    buildyear_comparabilty: AltumSettingsComparability;
    objectarea_comparabilty: AltumSettingsComparability;

    visual_comparability_weight: number;
    objectarea_weight: number;
    buildyear_weight: number;
    transaction_date_weight: number;
    distance_weight: number;

    transaction_date_limit: AltumSettingsTransactionDate;
    distance_filter: AltumSettingsDistance;

    housetype_to_search: AltumSettingsHouseType[];
}

export enum AltumSettingsHouseType {
    VRIJSTAAND = 'Vrijstaande woning',
    VRIJSTAAND_DOELGROEP = 'Vrijstaande doelgroepwoning',
    VRIJSTAAND_RECREATIE = 'Vrijstaande recreatiewoning',
    TWEE_ONDER_EEN_KAP = '2 onder 1 kap woning',
    GESCHAKELD_TWEE_ONDER_EEN_KAP = 'Geschakelde 2 onder 1 kapwoning',
    GESCHAKELD = 'Geschakelde woning',
    TUSSENWONING = 'Tussen/rijwoning',
    TUSSEN_DOELGROEP = 'Tussen/rij doelgroepwoning',
    HOEKWONING = 'Hoekwoning',
    EINDWONING = 'Eindwoning',
    GALERIJFLAT = 'Galerijflat',
    PORTIEKFLAT = 'Portiekflat',
    CORRIDORFLAT = 'Corridorflat',
    MAISONNETTE = 'Maisonnette',
    BENEDENWONING = 'Benedenwoning',
    BOVENWONING = 'Bovenwoning',
    PORTIEKWONING = 'Portiekwoning',
}

export enum AltumSettingsComparability {
    ALL = 0,
    COMPARABLE = 1,
    COMPARABLE_BETTER_LESSER = 2,
}

export enum AltumSettingsDistance {
    ALL = 'all',
    STREET = 'street',
    NEIGHBOURHOOD = 'neighbourhood',
    DISTRICT = 'district',
    RADIUS_1KM = 'radius_1km',
    RADIUS_5KM = 'radius_5km',
}

export enum AltumSettingsTransactionDate {
    MONTHS_6 = 6,
    YEAR_1 = 12,
    YEAR_2 = 24,
    YEAR_3 = 36,
    YEAR_4 = 48,
    YEAR_5 = 60,
}

export interface PreselectedReferenceObjectsApi {
    getReferenceSales(
        appraisalId: number,
        objectType?: ObjectType
    ): Promise<PreselectedReferenceObjectApiData | TaskReference>;

    savePreselection(appraisalId: number, preselection: ReferenceSale[], rejected: ReferenceSale[]): Promise<void>;
}

export class DefaultPreselectedReferenceObjectsApi implements PreselectedReferenceObjectsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public getReferenceSales(
        appraisalId: number,
        objectType?: ObjectType
    ): Promise<PreselectedReferenceObjectApiData | TaskReference> {
        const searchParams = new URLSearchParams(objectType ? [['object_type', objectType]] : []).toString();

        return new Promise<PreselectedReferenceObjectApiData | TaskReference>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/pre-select-reference-sales?${searchParams}`, {
                    method: 'GET',
                    credentials: 'same-origin',
                    headers: {
                        Accept: 'application/json',
                        'Content-Type': 'application/json',
                    },
                })
                .then(async (result) => {
                    if (result.ok) {
                        const data: PreselectedReferenceObjectApiData | ApiTaskReference = await result.json();
                        if (!data) {
                            reject();
                        } else if (isApiTaskReference(data)) {
                            resolve({
                                taskId: data.task_id,
                            });
                        } else {
                            resolve(data);
                        }
                    } else if (result.status === 400) {
                        const data: {reason?: string} = await result.json();
                        if (data && data.reason) {
                            reject(new ReferenceObjectsError(data.reason, data));
                        } else {
                            reject(result);
                        }
                    } else {
                        reject(result);
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public savePreselection(
        appraisalId: number,
        preselected: ReferenceSale[],
        rejected: ReferenceSale[]
    ): Promise<void> {
        return new Promise<void>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/appraisals/${appraisalId}/pre-select-reference-sales`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        preselected: preselected.map(referenceObjectToApiReferenceObject),
                        rejected: rejected.map(referenceObjectToApiReferenceObject),
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then((result) => {
                    if (result.ok) {
                        resolve();
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
