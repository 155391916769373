import {getCookieByName} from './get_cookie_by_name';

type ReturnType = {'X-XSRF-TOKEN': string} | {'X-CSRF-TOKEN': string} | Record<string, never>;

export function getCsrfHeader(): ReturnType {
    const xsrfToken = getCookieByName('XSRF-TOKEN');
    if (xsrfToken) {
        return {
            'X-XSRF-TOKEN': xsrfToken,
        };
    }

    const csrfToken = (document.head.querySelector('meta[name="csrf-token"]') as HTMLMetaElement).content;
    if (csrfToken) {
        return {
            'X-CSRF-TOKEN': csrfToken,
        };
    }

    return {};
}

export function getDefaultAjaxHeaders() {
    return {
        ...getCsrfHeader(),
        Accept: 'application/json',
        'Content-Type': 'application/json',
    };
}
