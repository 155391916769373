import {
    ApiConstructionCostsLine,
    apiConstructionCostsLinesToConstructionCostsLines,
} from './models/api_construction_costs_summary';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {ConstructionCostsLine} from '../models/construction_costs_summary';
import {getDefaultAjaxHeaders} from '../../support/api_headers';

export interface ConstructionCostsSummaryApi {
    get(constructionCostReference: string): Promise<ConstructionCostsLine[]>;
}

export class DefaultConstructionCostsSummaryApi implements ConstructionCostsSummaryApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public get(constructionCostReference: string): Promise<ConstructionCostsLine[]> {
        return new Promise<ConstructionCostsLine[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/construction-costs/list`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        construction_cost_reference: constructionCostReference,
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiConstructionCostsLines: ApiConstructionCostsLine[] = await result.json();
                        resolve(apiConstructionCostsLinesToConstructionCostsLines(apiConstructionCostsLines));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
