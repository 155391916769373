import {makeObservable, observable, runInAction} from 'mobx';
import {AppraisalProvider} from '../../../appraising/business/appraisal_provider';
import {Appraisal} from '../../../appraising/models/appraisal';
import {ObjectSummary} from '../../../appraising/models/object_summary';
import {AppraisalApi} from '../../../appraising/network/appraisal_api';
import {CompositeSubscription} from '../../../support/composite_subscription';
import {Presenter} from '../../../support/presenter/presenter';

export class WidgetPresenter implements Presenter {
    @observable.ref public appraisal?: Appraisal;
    @observable.ref public objectSummary?: ObjectSummary;
    @observable public noResult = false;

    private subscriptions = new CompositeSubscription();

    constructor(private appraisalProvider: AppraisalProvider, private appraisalApi: AppraisalApi) {
        makeObservable(this);
    }

    public mount(): void {
        this.subscriptions.add(
            this.appraisalProvider.stream.subscribe((appraisal) => {
                runInAction(() => {
                    this.appraisal = appraisal;

                    this.appraisalApi
                        .getObjectSummary(appraisal.id)
                        .then((objectSummary) => {
                            objectSummary.keywords =
                                objectSummary.keywords?.filter((summary) => {
                                    return !(
                                        summary === 'Onbekend' ||
                                        summary === 'Goed' ||
                                        summary === 'Redelijk' ||
                                        summary === 'Slecht' ||
                                        summary === 'Matig'
                                    );
                                }) ?? [];

                            runInAction(() => {
                                this.objectSummary = objectSummary;
                            });
                        })
                        .catch(() => {
                            runInAction(() => {
                                this.noResult = true;
                            });
                        });
                });
            })
        );
    }

    public unmount(): void {
        this.subscriptions.clear();
    }
}
