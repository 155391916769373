import {ApiAnswer, apiAnswerToAnswer} from './models/api_answer';

import {AjaxDriver} from '../../network/driver/ajax_driver';
import {Answer} from '../models/answer';
import {FileReference} from '../models/file_reference';
import {apiFileToFile} from './models/api_file';
import {getDefaultAjaxHeaders} from '../../support/api_headers';

export interface AppraisalAutomationApi {
    runCadastralAdditionalExcerptAutomation(
        appraisalId: number,
        municipal: string | null,
        section: string | null,
        number: string | null,
        index?: string | null
    ): Promise<Answer[]>;

    getCadastralDocument(
        appraisalId: number,
        register: string,
        part: string,
        number: string,
        series: string | null
    ): Promise<FileReference | null>;
}

export class DefaultAppraisalAutomationApi implements AppraisalAutomationApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public runCadastralAdditionalExcerptAutomation(
        appraisalId: number,
        municipal: string | null,
        section: string | null,
        number: string | null,
        index?: string | null
    ): Promise<Answer[]> {
        return new Promise<Answer[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/automations/appraisal/${appraisalId}/additional-cadastral-excerpt`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        municipal: municipal,
                        section: section,
                        number: number,
                        index: index,
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.ok) {
                        const apiAnswers: ApiAnswer[] = await result.json();
                        const isoCache: Record<string, Date> = {};
                        resolve(apiAnswers.map((apiAnswer) => apiAnswerToAnswer(apiAnswer, isoCache)));
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }

    public async getCadastralDocument(
        appraisalId: number,
        register: string,
        part: string,
        number: string,
        series: string | null
    ): Promise<FileReference | null> {
        try {
            const response = await this.ajaxDriver.fetch(`/ajax/appraisals/${appraisalId}/cadastral-document`, {
                method: 'POST',
                credentials: 'same-origin',
                body: JSON.stringify({
                    register: register,
                    part: part,
                    number: number,
                    series: series,
                }),
                headers: getDefaultAjaxHeaders(),
            });
            if (response.ok) {
                const data = await response.json();
                return apiFileToFile(data);
            }

            return null;
        } catch (e) {
            console.warn(e);
            return null;
        }
    }
}
