import {AjaxDriver} from '../../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../../support/api_headers';
import {UserWidget} from '../enum/user_widget';

export interface UserWidgetApi {
    store(widgets: UserWidget[]): Promise<UserWidget[]>;
}

export class DefaultUserWidgetApi implements UserWidgetApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public store(widgets: UserWidget[]): Promise<UserWidget[]> {
        return new Promise<UserWidget[]>((resolve, reject) => {
            this.ajaxDriver
                .fetch(`/ajax/user-widgets/appraise/update`, {
                    method: 'POST',
                    credentials: 'same-origin',
                    body: JSON.stringify({
                        widgets: widgets,
                    }),
                    headers: getDefaultAjaxHeaders(),
                })
                .then(async (result) => {
                    if (result.ok) {
                        resolve(await result.json());
                    } else {
                        reject();
                    }
                })
                .catch((error) => reject(error));
        });
    }
}
