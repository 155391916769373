import {AjaxDriver} from '../../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../../support/api_headers';
import {ComposedNotificationType} from '../enum/composed_notification_type';

export interface ComposedNotificationsApi {
    notify(appraisalId: number, type: ComposedNotificationType): Promise<boolean | null>;
}

export class DefaultComposedNotificationsApi implements ComposedNotificationsApi {
    constructor(private ajaxDriver: AjaxDriver) {}

    public notify(appraisalId: number, type: ComposedNotificationType): Promise<boolean | null> {
        return this.call(`/ajax/notifications/${appraisalId}/notify-composed`, {
            method: 'POST',
            body: JSON.stringify({
                type: type,
            }),
        });
    }

    private async call(url: string, init: RequestInit): Promise<boolean | null> {
        try {
            const requestInit: RequestInit = {
                ...init,
                credentials: 'same-origin',
                headers: getDefaultAjaxHeaders(),
            };
            const response = await this.ajaxDriver.fetch(url, requestInit);
            return response.ok;
        } catch (e) {
            console.warn(e);
            return null;
        }
    }
}
