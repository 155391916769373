import * as React from 'react';

import {Appraisal} from '../../../../models/appraisal';
import {observer} from 'mobx-react';
import {usePresenter} from '../../../../../support/presenter/use_presenter';
import {NeighbourhoodDetailsData} from '../../../../models/object_summary';
import {Collapsible} from '../../components/collapsible';
import {
    getLabelForNeighbourhoodDetail,
    getLabelForSubNeighbourhoodDetail,
    getUnitForSubNeighbourhoodDetail,
} from '../../../../../create_appraisal/pre_check/object/NeighbourhoodDetailsSupport';
import {NeighbourhoodMap} from '../../../../../create_appraisal/pre_check/neighbourhood_map/neighbourhood_map';
import {AppraisalMatrixianBuurtinfoWidgetPresenter} from './appraisal_details_matrixian_buurtinfo_presenter';
import {NeighbourhoodMarriageDetails} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_marriage_details';
import {NeighbourhoodCompanyDetails} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_company_details';
import {NeighbourhoodAgeDetails} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_age_details';
import {NeighbourhoodIncomeGauge} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_income_gauge_chart';
import {Loader} from '../../components/loader';
import {NeighbourhoodBuildyearGauge} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_buildyear_gauge_chart';
import {NeighbourhoodOwnershipGauge} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_ownership_gauge_chart';
import {NeighbourhoodBewoondGauge} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_bewoond_gauge_chart';
import {NeighbourhoodEensgezinsGauge} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_rent_gauge_chart';
import {NeighbourhoodHuishoudensGauge} from '../../../../../create_appraisal/pre_check/neighbourhood_data/neighbourhood_huishoudens_gauge_chart';
import {classNames} from '../../../../../support/classnames';
interface OwnProps {
    appraisal: Appraisal;
    onDiscard: () => void;
}

export const AppraisalMatrixianBuurtinfoWidget: React.FC<OwnProps> = observer(function AppraisalDetailsWidget(props) {
    const presenter = usePresenter(
        (container) => new AppraisalMatrixianBuurtinfoWidgetPresenter(props.appraisal, container.network.appraisalApi)
    );
    const [closing, setClosing] = React.useState(false);

    return (
        <div className={classNames('pdf-overlay', {closing})}>
            <div className="col-12">
                <div className="widget">
                    <div className="widget-title">
                        <h3>
                            <span className="ion-md-home" />
                            Buurtinformatie
                        </h3>
                        <button
                            className="widget-overlay-close"
                            onClick={() => {
                                setClosing(true);
                                setTimeout(() => {
                                    props.onDiscard();
                                }, 300);
                            }}
                        >
                            <span className="ion-md-close">&nbsp;</span>
                        </button>
                    </div>
                    <div className="widget-body">
                        {presenter.loading && (
                            <span className="list-avatar-loader">
                                <Loader addSpace={false} />
                            </span>
                        )}
                        {presenter.noResult && (
                            <div className="col-12 pl-5 pr-5 mt-3 mb-3">
                                <div className="empty-message appraise mt-0">
                                    <h3>Er zijn geen gegevens gevonden.</h3>
                                    <span className="spacer-gradient">&nbsp;</span>
                                </div>
                            </div>
                        )}
                        {presenter.objectSummary?.cbsData && (
                            <>
                                <h1>{presenter.objectSummary.neighbourhoodDetails?.wijkenEnBuurten}</h1>
                                <div className="row">
                                    <div className="col-6">
                                        <NeighbourhoodMarriageDetails
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                            cbsData={presenter.objectSummary.cbsData.CbsBurgelijkeStaat}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NeighbourhoodCompanyDetails
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <NeighbourhoodAgeDetails
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodIncomeGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: 240}}>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodBuildyearGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NeighbourhoodOwnershipGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: 240}}>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodBewoondGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NeighbourhoodEensgezinsGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: 240}}>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodHuishoudensGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <NeighbourhoodMap
                                    neighbourhoodDetails={presenter.objectSummary.neighbourhoodDetails}
                                    appraisal={presenter.appraisal}
                                />
                            </>
                        )}
                        {presenter.objectSummary?.neighbourhoodDetails &&
                            Object.keys(presenter.objectSummary.neighbourhoodDetails).map((key) => {
                                if (!presenter.objectSummary?.neighbourhoodDetails) {
                                    return;
                                }

                                const sectionData =
                                    presenter.objectSummary.neighbourhoodDetails[key as keyof NeighbourhoodDetailsData]; // Access the data dynamically

                                if (
                                    !sectionData ||
                                    key === 'neighbourhoodGeometry' ||
                                    key === 'neighbourhoodBedrijfsvestigingen' ||
                                    key === 'wijkenEnBuurten' ||
                                    key === 'algemeen'
                                )
                                    return null; // Skip null values
                                return (
                                    <Collapsible key={key} title={getLabelForNeighbourhoodDetail(key)}>
                                        <div>
                                            {Object.keys(sectionData).map((subKey) => {
                                                const label = getLabelForSubNeighbourhoodDetail(key, subKey);
                                                const unit = getUnitForSubNeighbourhoodDetail(key, subKey);
                                                let value: string | object =
                                                    sectionData[subKey as keyof typeof sectionData];

                                                if (value === null) {
                                                    return null;
                                                }

                                                if (!presenter.objectSummary?.cbsData) {
                                                    return null;
                                                }

                                                if (key === 'neighbourhoodEnergie') {
                                                    if (subKey.toLowerCase().includes('gas')) {
                                                        value = Math.round(
                                                            value * presenter.objectSummary.cbsData.CbsEnergie.gas
                                                        ).toString();
                                                    }
                                                }

                                                if (typeof value === 'object') {
                                                    if ('hideAsText' in value && value['hideAsText']) {
                                                        return null;
                                                    } else {
                                                        value = value['value'];
                                                    }
                                                }

                                                let showUnit = true;
                                                if (unit.toLowerCase() === 'aantal') {
                                                    showUnit = false;
                                                }

                                                return (
                                                    <div key={subKey} className="d-flex justify-content-between">
                                                        <span>{label}</span>
                                                        <span>
                                                            {value} {showUnit ? unit : null}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Collapsible>
                                );
                            })}
                        <p>** Let op: deze data is bijgewerkt tot 2018. We werken aan een update. </p>
                    </div>
                </div>
            </div>
        </div>
    );
});
