import {observer} from 'mobx-react';
import React from 'react';
import {Loader} from '../../../appraising/appraise/ui/components/loader';
import {usePresenter} from '../../../support/presenter/use_presenter';
import {Images} from './images';
import {WidgetPresenter} from './widget_presenter';
import {WordCloud} from './word_cloud';
import {NeighbourhoodMap} from '../neighbourhood_map/neighbourhood_map';
import {Collapsible} from '../../../appraising/appraise/ui/components/collapsible';
import {
    getLabelForNeighbourhoodDetail,
    getLabelForSubNeighbourhoodDetail,
    getUnitForSubNeighbourhoodDetail,
} from './NeighbourhoodDetailsSupport';
import {NeighbourhoodDetailsData} from '../../../appraising/models/object_summary';
import {NeighbourhoodMarriageDetails} from '../neighbourhood_data/neighbourhood_marriage_details';
import {NeighbourhoodCompanyDetails} from '../neighbourhood_data/neighbourhood_company_details';
import {NeighbourhoodAgeDetails} from '../neighbourhood_data/neighbourhood_age_details';
import {NeighbourhoodIncomeGauge} from '../neighbourhood_data/neighbourhood_income_gauge_chart';
import {NeighbourhoodBuildyearGauge} from '../neighbourhood_data/neighbourhood_buildyear_gauge_chart';
import {NeighbourhoodOwnershipGauge} from '../neighbourhood_data/neighbourhood_ownership_gauge_chart';
import {NeighbourhoodBewoondGauge} from '../neighbourhood_data/neighbourhood_bewoond_gauge_chart';
import {NeighbourhoodEensgezinsGauge} from '../neighbourhood_data/neighbourhood_rent_gauge_chart';
import {NeighbourhoodHuishoudensGauge} from '../neighbourhood_data/neighbourhood_huishoudens_gauge_chart';

export const Widget: React.FC = observer(() => {
    const presenter = usePresenter(
        (component) => new WidgetPresenter(component.business.appraisalProvider, component.network.appraisalApi)
    );

    return (
        <div className="card pre-check-widget">
            <div className="photos">
                <div className="address">
                    <span className="icon-pin" />
                    {presenter.appraisal?.address} {presenter.appraisal?.houseNumber}
                    {presenter.appraisal?.letter}, {presenter.appraisal?.postalCode} {presenter.appraisal?.city}
                </div>
                <Images />
            </div>
            <div className="object-details">
                {presenter.objectSummary ? (
                    <>
                        {presenter.objectSummary.objectType && (
                            <span className="object-type">{presenter.objectSummary.objectType}</span>
                        )}
                        <div className="d-flex justify-content-between mt-4 mb-3 object-properties">
                            {presenter.objectSummary.buildYear ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="c-icon-build-year pr-1" />
                                        <span>{presenter.objectSummary.buildYear}</span>
                                    </span>
                                </div>
                            ) : null}
                            {presenter.objectSummary.surfaceArea ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="c-icon-floor-area pr-1" />
                                        <span>{presenter.objectSummary.surfaceArea} m²</span>
                                    </span>
                                </div>
                            ) : null}
                            {presenter.objectSummary.plotArea ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="c-icon-plot-area pr-1" />
                                        <span>{presenter.objectSummary.plotArea} m²</span>
                                    </span>
                                </div>
                            ) : null}
                            {presenter.objectSummary.energyLabel ? (
                                <div className="col-3 d-flex justify-content-center">
                                    <span className={'badge badge-large d-flex align-items-center'}>
                                        <span className="ion-md-flash pr-1" style={{fontSize: 30}} />
                                        {presenter.objectSummary.energyLabel ?? '-'}
                                    </span>
                                </div>
                            ) : null}
                        </div>
                        <WordCloud words={presenter.objectSummary.keywords ?? []} />
                        {presenter.objectSummary.cbsData && presenter.objectSummary.neighbourhoodDetails && (
                            <>
                                <h1>{presenter.objectSummary.neighbourhoodDetails.wijkenEnBuurten}</h1>
                                <div className="row">
                                    <div className="col-6">
                                        <NeighbourhoodMarriageDetails
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                            cbsData={presenter.objectSummary.cbsData.CbsBurgelijkeStaat}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NeighbourhoodCompanyDetails
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row">
                                    <div className="col-6">
                                        <NeighbourhoodAgeDetails
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodIncomeGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: 240}}>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodBuildyearGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NeighbourhoodOwnershipGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: 240}}>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodBewoondGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                    <div className="col-6">
                                        <NeighbourhoodEensgezinsGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <div className="row" style={{maxHeight: 240}}>
                                    <div className="col-6" style={{width: '100%'}}>
                                        <NeighbourhoodHuishoudensGauge
                                            neighbourhoodDetailsData={presenter.objectSummary.neighbourhoodDetails}
                                        />
                                    </div>
                                </div>
                                <NeighbourhoodMap
                                    neighbourhoodDetails={presenter.objectSummary.neighbourhoodDetails}
                                    appraisal={presenter.appraisal}
                                />
                            </>
                        )}
                        {presenter.objectSummary?.neighbourhoodDetails &&
                            Object.keys(presenter.objectSummary.neighbourhoodDetails).map((key) => {
                                if (
                                    presenter.objectSummary?.neighbourhoodDetails === undefined ||
                                    presenter.objectSummary?.neighbourhoodDetails === null
                                ) {
                                    return;
                                }

                                const sectionData =
                                    presenter.objectSummary.neighbourhoodDetails[key as keyof NeighbourhoodDetailsData]; // Access the data dynamically

                                if (
                                    !sectionData ||
                                    key === 'neighbourhoodGeometry' ||
                                    key === 'neighbourhoodBedrijfsvestigingen' ||
                                    key === 'wijkenEnBuurten' ||
                                    key === 'algemeen'
                                )
                                    return null; // Skip null values
                                return (
                                    <Collapsible key={key} title={getLabelForNeighbourhoodDetail(key)}>
                                        <div>
                                            {Object.keys(sectionData).map((subKey) => {
                                                const label = getLabelForSubNeighbourhoodDetail(key, subKey);
                                                const unit = getUnitForSubNeighbourhoodDetail(key, subKey);
                                                let value: string | object =
                                                    sectionData[subKey as keyof typeof sectionData];

                                                if (value === null) {
                                                    return null;
                                                }

                                                if (
                                                    presenter.objectSummary?.cbsData === undefined ||
                                                    presenter.objectSummary?.cbsData === null
                                                ) {
                                                    return null;
                                                }

                                                if (key === 'neighbourhoodEnergie') {
                                                    if (subKey.toLowerCase().includes('gas')) {
                                                        value = Math.round(
                                                            value * presenter.objectSummary.cbsData.CbsEnergie.gas
                                                        ).toString();
                                                    }
                                                }

                                                if (typeof value === 'object') {
                                                    if ('hideAsText' in value && value['hideAsText']) {
                                                        return null;
                                                    } else {
                                                        value = value['value'];
                                                    }
                                                }

                                                let showUnit = true;
                                                if (unit.toLowerCase() === 'aantal') {
                                                    showUnit = false;
                                                }

                                                return (
                                                    <div key={subKey} className="d-flex justify-content-between">
                                                        <span>{label}</span>
                                                        <span>
                                                            {value} {showUnit ? unit : null}
                                                        </span>
                                                    </div>
                                                );
                                            })}
                                        </div>
                                    </Collapsible>
                                );
                            })}
                        <p>** Let op: deze data is bijgewerkt tot 2018. We werken aan een update. </p>
                    </>
                ) : presenter.noResult ? (
                    <div className="col-12 pl-5 pr-5 mt-3 mb-3">
                        <div className="empty-message appraise mt-0">
                            <h3>Er zijn geen gegevens gevonden.</h3>
                            <span className="spacer-gradient">&nbsp;</span>
                        </div>
                    </div>
                ) : (
                    <div className="d-flex justify-content-center">
                        <Loader isSmall />
                    </div>
                )}
            </div>
        </div>
    );
});
