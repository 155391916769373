import {makeObservable, observable, runInAction} from 'mobx';
import {AppraiserStatistic} from '../appraising/models/appraiser_statistic';
import {
    ApiAppraiserStatistic,
    apiAppraiserStatisticToAppraiserStatistic,
} from '../appraising/network/models/api_appraiser_statistic';
import {CompositeSubscription} from '../support/composite_subscription';
import {Presenter} from '../support/presenter/presenter';
import {ApiAppraisal, apiAppraisalToAppraisal} from '../appraising/network/models/api_appraisal';
import {Appraisal} from '../appraising/models/appraisal';
import {AjaxDriver} from '../network/driver/ajax_driver';
import {getDefaultAjaxHeaders} from '../support/api_headers';

interface ApiYearOverview {
    year: number;
    nr_of_corrections: number;
    statistics: ApiAppraiserStatistic[];
    quickest_appraisal: {
        appraisal: ApiAppraisal;
        creation_to_validation_seconds: number;
        image_url: string | null;
    } | null;
    most_corrected_appraisal: {
        appraisal: ApiAppraisal;
        corrections: number;
        image_url: string | null;
    } | null;
    most_answers_appraisal: {
        appraisal: ApiAppraisal;
        answer_count: number;
        image_url: string | null;
    } | null;
}

interface YearOverview {
    year: number;
    statistics: AppraiserStatistic[];
    nrOfCorrections: number;
    quickestAppraisal: {
        appraisal: Appraisal;
        creationToValidationSeconds: number;
        imageUrl: string | null;
    } | null;
    mostCorrectedAppraisal: {
        appraisal: Appraisal;
        corrections: number;
        imageUrl: string | null;
    } | null;
    mostAnswersAppraisal: {
        appraisal: Appraisal;
        answerCount: number;
        imageUrl: string | null;
    } | null;
}

export class RootPresenter implements Presenter {
    @observable public data: YearOverview | null = null;
    @observable public error = false;
    @observable public loadingPdf = false;
    @observable public pdfUrl: string | null = null;

    private readonly subscriptions = new CompositeSubscription();

    constructor(private readonly ajaxDriver: AjaxDriver) {
        makeObservable(this);
    }

    public mount(): void {
        this.fetch()
            .then((data) => {
                runInAction(() => {
                    this.data = data;
                });
            })
            .catch((e) => {
                runInAction(() => {
                    this.error = true;
                });
                console.error(e);
            });
    }

    public unmount(): void {
        this.subscriptions.clear();
    }

    public getStatistic(key: string) {
        return this.data?.statistics.find((statistic) => statistic.key === key)?.total ?? 0;
    }

    private async fetch(): Promise<YearOverview> {
        const res = await this.ajaxDriver.fetch(`/ajax/overview/year`, {
            credentials: 'same-origin',
            headers: getDefaultAjaxHeaders(),
            method: 'GET',
        });

        if (!res.ok) throw new Error('Could not fetch year overview');

        const data = (await res.json()) as ApiYearOverview;

        return {
            year: data.year,
            statistics: data.statistics.map((statistic) => apiAppraiserStatisticToAppraiserStatistic(statistic)),
            nrOfCorrections: data.nr_of_corrections,
            quickestAppraisal: data.quickest_appraisal
                ? {
                      appraisal: apiAppraisalToAppraisal(data.quickest_appraisal.appraisal),
                      creationToValidationSeconds: data.quickest_appraisal.creation_to_validation_seconds,
                      imageUrl: data.quickest_appraisal.image_url,
                  }
                : null,
            mostCorrectedAppraisal: data.most_corrected_appraisal
                ? {
                      appraisal: apiAppraisalToAppraisal(data.most_corrected_appraisal.appraisal),
                      corrections: data.most_corrected_appraisal.corrections,
                      imageUrl: data.most_corrected_appraisal.image_url,
                  }
                : null,
            mostAnswersAppraisal: data.most_answers_appraisal
                ? {
                      appraisal: apiAppraisalToAppraisal(data.most_answers_appraisal.appraisal),
                      answerCount: data.most_answers_appraisal.answer_count,
                      imageUrl: data.most_answers_appraisal.image_url,
                  }
                : null,
        };
    }
}
